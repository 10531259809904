.toggleContainer {
  display: flex;
  width: 400px;
  margin: 20px auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background-color: #e0e0e0;
  cursor: pointer;
  align-items: center;
}

.movingBackground {
  flex: 1;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #333;
  transition: transform 0.3s ease;
  border-radius: 18px;
  transform: translateX(0);
}

.movingBackground.right {
  transform: translateX(100%);
}

.label {
  flex: 1;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff; /* Цвет по умолчанию для неактивного состояния белый */
  background-color: transparent;
  border: none;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.labelActive {
  color: #333; /* Цвет для активного состояния темный */
}

.plansContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* max-width: 900px; */
}

.plan {
  width: 45%;
  margin-bottom: 20px;
  padding: 40px; /* Увеличенный внутренний отступ */
  border: 1px solid #ccc;
  border-radius: 15px;
  min-height: 280px;
  position: relative; /* Для позиционирования элемента .activeLabel */
}

.planTitle {
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.price {
  font-size: 30px;
  font-weight: bold;
  color: #000; /* Черный цвет для цены */
}

.description {
  font-size: 12px;
  font-weight: normal;
  color: #444;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  background-color: #007BFF;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.componentContainer {
  text-align: center;
}

/* Новый стиль для активной подписки */
.activeLabel {
  background-color: #808080; /* Серая подложка */
  color: #fff; /* Белый цвет текста */
  padding: 4px 8px; /* Маленькие отступы вокруг текста */
  border-radius: 12px; /* Легкое скругление углов */
  font-weight: bold; /* Жирный текст */
  display: inline-block; /* Блочный элемент */
  position: absolute; /* Абсолютное позиционирование */
  top: 40px; /* Смещение вниз от верхнего края блока */
  left: 50%;
  transform: translateX(-50%); /* Центрирование по горизонтали */
  text-transform: uppercase; /* Преобразование текста в верхний регистр */
  font-size: 11px; /* Маленький размер текста */
}

/* Стили для активного плана */
.plan.active {
  border: 2px solid #007BFF; /* Синяя рамка вокруг активного плана */
}

.plan.active .price {
  color: #000; /* Черный цвет текста для цены активного плана */
}
