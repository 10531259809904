
body {
  background-color: var(--page-background);
  color: var(--page-primary);
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  /* padding-top: 50px;  */
  transition: background-color 0.3s ease, color 0.3s ease;
 

}
a:visited {
  color: var(--page-primary); 
  text-decoration: none; /* Можем убрать подчеркивание, если нужно */
}
/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  body {
      padding-top: 100px; /* Увеличенный отступ для мобильных устройств */
  }
}

.small-h1-title {
  font-size: 24px;
}


header nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
}

header nav a:hover {
    text-decoration: underline;
}


.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.page-container {
    /* background-color: blueviolet; */
    max-width: 1500px;
    margin-top: 100px;
    margin: 0 auto;
    padding: 0 0px; 
    width: 90%;
}
.page-container-with-top-margin {
  /* background-color: blueviolet; */
  max-width: 1500px;
  margin-top: 100px;
  margin: 100px auto 0 auto;
  padding: 0 0px; 
  width: 90%;
}
.small-page-container {
  /* background-color: blueviolet; */
  max-width: 900px;
  margin: 100px auto;
  padding: 0 0px; /* Отступы по бокам для небольшого пространства */
  width: 90%;
}
.search-page-container-with-top-margin {
  align-items: center;
  text-align: center;
  /* background-color: blueviolet; */
  margin: 100px auto 0 auto;
  padding: 0 0px; 
  width: 98%;
}
/* ---------------------------------- */


.cart {
  width: 80%;
  margin: auto;
}

.cart-items {
  display: flex;
  flex-direction: column;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.cart-item img {
  width: 50px;
  height: 50px;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.cart-total {
  text-align: right;
}

.cart-total span {
  font-size: 1.5rem;
  margin-right: 10px;
}

/* DownloadImageButton.css */
.download-button {
  background-color: var(--page-primary); 
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--page-background);           /* White text */
  padding: 20px 40px;             /* Increased padding for a larger button */
  border: none;                   /* No border */
  cursor: pointer;                /* Pointer cursor on hover */
  border-radius: 50px;            /* Large border-radius for a round shape */
  font-size: 16px;                /* Keep text size unchanged */
  /* transition: background-color 0.2s ease, transform 0.1s ease;  */
  transition: color 0.3s ease;
  align-self: flex-start;
}

.download-button:hover {
  background-color: var(--page-secondary);      /* Darker shade on hover */
  transform: scale(1.02);         /* Slightly enlarge on hover */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.download-button:active {
  transform: scale(0.98);         /* Slightly shrink on click */
}

.red {
  background-color: red;
  color: white; /* Вы можете также изменить цвет текста, если необходимо */
  border: none; /* Убирает стандартную рамку */
  padding: 10px 20px; /* Настройка отступов */
  cursor: pointer; /* Изменяет курсор на руку при наведении */
  font-size: 16px; /* Настройка размера шрифта */
}
h1 {
  font-size: 38px;
  margin-bottom: 0px;
}


.sticky-footer-wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr 100px;
}
/* Добавьте в ваш CSS-файл */
.react-photo-album--image {
  border-radius: 10px;
}
.header-meta {
  background-color: var(--page-background);
  color: var(--page-primary);
  padding: 0;
  margin: 0;
  width: 100%;
  top: 0px;
  justify-content: center;
  position: fixed;
  z-index: 1150;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header {
  display: flex;
  justify-content: center;
  padding: 0;
  z-index: 1149;
}

.logo-container {
  grid-column: span 2;
}

.logo-container a {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.search-container {
  grid-column: span 4;
  align-items: center;
  position: relative;
  border-radius: 40px;
  margin-bottom: 7px;
}

.search-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 16px;
  top: 15px;
 
}

.search-container input {
  border: none;
  font-size: 15px;
  width: 100%;
  height: 24px;
  font-family: 'Poppins';
  transition: background-color 0.3s ease, color 0.3s ease;
}

.search-container input::placeholder {
  color: #656565;
}

.search-container input:focus {
  outline: none;
}

.menu-container {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.item-count {
  background-color: black;
  color: white;
  border-radius: 100%;
  min-width: 20px;
  min-height: 20px;
  font-size: 0.6rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 8px; */
  margin-bottom:3px
}

.cart-block {
  margin-right: 16px;
}

.cart-icon {
  width: 19.2px;
  height: 19.2px;
}

.mainmenu-button {
  cursor: pointer;
}

.menu-burger-icon {
  width: 19.2px;
  height: 19.2px;
}

.header-container {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  width: 90%;
  top: 0px;
  margin: 0 auto;
  padding: 10px 0px;
}

/* Media Queries для мобильных устройств */
@media (max-width: 1100px) {
  body {
    padding-top: 100px; /* Увеличенный отступ для мобильных устройств */
}

  .header {
      display: flex;
      flex-direction: column;
      /* align-items: flex-start; */
      padding: 0px 0;
  }
  .header-container {
    padding: 15px 0;
    display: grid;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    top: 0px;
    margin: 0 auto;
    padding: 10px 0px;
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 0;
    width: 98%;
  }
  .logo-container {
      display: flex;
      justify-content: left;
      align-items: center;
      height: 70px;
      order: 1;
      grid-column: span 2;
      padding-left:13px;
  }
  .menu-container {
      order: 2;
  }

  .search-container {
      order: 3;
      padding-left:10px;
      padding-right:10px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .search-container input {
      width: calc(100% - 50px);
  }


  .page-container-with-top-margin {
      width: 93%;
      margin-top: 130px;
  }
  .search-page-container-with-top-margin {
    margin: 130px auto 0 auto;
    padding: 0 0px; 
    width: 98%;
  }
  .page-container {
      width: 93%;
  
}

}

/* ---------------------------------------- */

/* Global.css */

/* Container for the entire search input component */
.search-input-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* Loading bar at the top */
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* background-color: #000; */
  animation: loading 2s linear infinite;
  z-index: 1001; /* Ensure it stays above other elements */
}

/* Keyframes for loading animation */
@keyframes loading {
  0% { left: -100%; }
  50% { left: 0%; }
  100% { left: 100%; }
}

/* Search form styling */
.search-form {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
}

/* Wrapper for dropdown and input */
.input-wrapper {
  display: flex;
  width: 100%;
}

/* Dropdown selector styling */
.dropdown-selector {
  position: relative;
  margin-right: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 19px 20px 19px 25px;
  font-size: 14px;
  background-color: var(--page-background-shaded);
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 100px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Dropdown label */
.dropdown-label {
  text-transform: capitalize;
}

/* Dropdown arrow styling */
.dropdown-arrow {
  width: 0;
  height: 0;
  margin-left: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--page-primary);
  transition: transform 0.3s;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

/* Dropdown menu styling */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--page-background-shaded);
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
  list-style: none;
  padding: 8px 0;
  margin: 0;
  width: 100%;
  z-index: 1000;
}

/* Dropdown items */
.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 14px;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: var(--page-primary);
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--page-background);
 
}

.dropdown-item.active {
  
  /* background-color: #e0e0e0; */
}

/* Wrapper for search input and lens icon */
.search-input-wrapper {
  position: relative;
  flex: 1;
}

/* Search input field styling */
.search-input {
  width: 100%;
  padding: 19px 0px 19px 50px;
  font-size: 14px;
  outline: none;
  background-color: var(--page-background-shaded);
  color: var(--page-primary);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}
.search-input::placeholder {
  color: var(--page-primary) !important;
  opacity: 0.5;
}

/* Hidden submit button */
.hidden-button {
  display: none;
}

.search-lens-icon {
  position: absolute;
  left:17px;
  top: 22px;
}

.search-results-title {
  font-size: 36px;
  margin-bottom: 50px;
  line-height: 1;
}
