.assets-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 40px;
    padding: 20px;
}

.asset-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-asset-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
