.color-set-preview {
  display: flex;
  cursor: pointer;
  border-radius: 11px;
  overflow: hidden;
  width: 100px;
  height: 50px;
  margin: 5px;
  padding: 3px; /* Добавляем отступы для эффекта паспарту */
  box-sizing: border-box;
  background-color: #ffffff; /* Белый фон, как паспарту */
  transition: border 0.3s;
  border: 1px solid #8d8d8d; /* Четкая рамка для активного элемента */
}

.color-set-preview.active {
  border: 3px solid #000; /* Четкая рамка для активного элемента */
}

.color-block {
  flex: 1;
  margin: 0;
  height: 100%; /* Заставляем блоки занимать всю высоту контейнера */
}

.color-block.first {
  border-radius: 7px 0 0 7px;
}

.color-block.last {
  border-radius: 0 7px 7px 0;
}
