/* AssetDetail.css */

/* Общий контейнер компонента */
.image-detail-container {
    margin-top: 20px;
}

/* Стили для состояния загрузки */
.loading {
    text-align: center;
    font-size: 1.2em;
    padding: 20px;
}

/* Стили для состояния ошибки */
.error {
    color: red;
    text-align: center;
    font-size: 1.2em;
    padding: 20px;
}

/* Основная секция контента */
.image-detail-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

/* Контейнер изображения */
.image-container {
    display: flex;
    justify-content: flex-end; /* Выравнивание изображения вправо */
    align-items: flex-start;
    min-width: 35%;
}

/* Стили для изображения */
.asset-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 15px;
    /* viewTransitionName нельзя задать через CSS, оставляем как inline prop */
    /* aspect-ratio задается inline для динамического значения */
    /* object-fit: contain; Если необходимо, можно раскомментировать */
}

/* Контейнер информации об изображении */
.image-info {
    width: 45%;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Стили для ключевых слов */
.keywords {
    font-size: 0.7em;
}

/* Секция похожих фотографий */
.similar-photos-section {
    margin-top: 40px;
}

/* Стили для сообщения об отсутствии ресурса */
.asset-not-found {
    text-align: center;
    font-size: 1.2em;
    padding: 20px;
}

/* Мобильная версия для экранов с шириной до 768px */
@media (max-width: 768px) {
    /* Общий контейнер компонента */
    .image-detail-container {
        margin-top: 10px;
        /* padding: 10px;   */
    }

    /* Основная секция контента */
    .image-detail-content {
        flex-direction: column;
        align-items: flex-start; /* Левое выравнивание */
    }

    /* Контейнер изображения */
    .image-container {
        justify-content: center; /* Левое выравнивание изображения */
        min-width: 100%;
        margin-bottom: 10px; /* Уменьшенный отступ */

    }

    /* Стили для изображения */
    .asset-image {
        max-width: 100%;
        height: auto; /* Автоматическая высота для сохранения пропорций */
        border-radius: 10px;
    }

    /* Контейнер информации об изображении */
    .image-info {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
        align-items: flex-start; /* Левое выравнивание */
        text-align: left; /* Левое выравнивание текста */
    }

    /* Стили для заголовка */
    .image-info h2 {
        font-size: 1.5em;
    }

    /* Стили для ключевых слов */
    .keywords {
        display: none;
        font-size: 0.9em;
    }

    /* Стили для текста о бесплатности/премиумности */
    .image-info p {
        font-size: 1em;
    }

    /* Секция похожих фотографий */
    .similar-photos-section {
        margin-top: 20px;
    }

    /* Стили для состояния загрузки и ошибки */
    .loading,
    .error,
    .asset-not-found {
        font-size: 1em;
        padding: 15px;
    }
}
