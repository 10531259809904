html, body, #root {
    height: 100%;  /* Устанавливаем высоту всей страницы */
    margin: 0;     /* Убираем стандартные отступы */
}

.main-content {
    min-height: 100%;
    /* Отталкиваем футер от содержимого */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    color: var(--page-primary);
    border-top: 1px solid #e2e2e2;
    margin-top: auto;  /* Автоматический отступ сверху для футера */
    margin-top: 30px;
    transition: color 0.3s ease;

}

.footer-content {
    display: flex;
    justify-content: space-between;  /* Выравниваем элементы по сторонам */
    align-items: center;
}

.footer-links {
    display: flex;
    gap: 10px;
}

.footer-links a {
    color: var(--page-primary);
    transition: color 0.3s ease;

}

.footer-links a:hover {
    text-decoration: underline;
}
