/* ../../styles/SimilarIcons.css */

.similar-icons-container {
  background-color: transparent;
}

.icons-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.icons-grid > *:nth-last-child(n+2):nth-child(odd) {
  justify-content: flex-start;
}








.icon-item {

  border-radius: 8px;
  transition: transform 0.2s;
  flex: 0 1 100px; /* Позволяет элементам иметь минимальную ширину 100px */
}

.icon-item:hover {
  transform: scale(1.1);
}

.icon-item img {
  width: 100%;
  height: auto;
  display: block;
}

.free-badge {
  position: absolute;
  top: 8px;
  left: 8px;

  color: #fff;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
}

.error-message {
  margin-top: 10px;
}
