/* Основные стили для контейнера */
.landing-container {
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
  }
  
  /* Стили для плиток */
  .landing-category {
    flex: 1;
    transition: transform 0.5s ease, flex-grow 0.5s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    padding: 10px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    height: 450px;
  }
  
  .landing-category h2 {
    font-size: 2rem;
    display: block;
  }
  
  /* Эффект для стационарных устройств: плитки разъезжаются */
  @media (min-width: 769px) {
    .landing-category:hover {
      flex-grow: 6;
      transform: scale(1.00);
    }
  }
  
  /* Мобильная версия: плитки собираются вертикально */
  @media (max-width: 768px) {
    .landing-container {
      flex-direction: column;
      gap: 20px;
    }
  
    .landing-category {
      height: 300px;
      transform: scale(1);
    }
  
    .landing-category:hover {
      transform: scale(1); /* Отключаем анимацию на мобильных устройствах */
    }
  
    .landing-category h2 {
      font-size: 1.5rem; /* Уменьшаем размер текста на мобильных */
    }
  }
  