.container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    padding-top: 30px;
  }
  
  .mainContent {
    display: flex;
    gap: 70px;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    /* max-width: 1200px; */
  }
  
  .svgContainer {
    padding: 50px;
    width: 30%;   
    /* min-width: 312px; */
    height: 256px;
    overflow: hidden;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-width: 1px;
    border-style: solid;
    border-color: var(--page-background-shaded);
   

    
    border-radius: 20px;
    background-color: #FFFFFF; /* Можно динамически изменять через inline-стили или CSS-переменные */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .controls {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    
    gap: 10px;
  }
  
  .titleKeywords {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .asset-title {
    font-size: 34px; /* Уменьшите или увеличьте по необходимости */
    margin-top: 5px;
    transition: color 0.3s ease;
    /* Дополнительные стили, если нужно */
  }
  
  .keywords {
    font-size: 11px; /* Установлено по требованию */
  }
  
  .additionalText {
    margin-top: 10px;
    font-style: italic;
  }
  
  .colorSets {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
/* Стандартные стили для больших экранов остаются, как у вас указано */

/* Медиа-запросы для мобильных устройств (например, ширина экрана меньше 768px) */
@media (max-width: 768px) {
  .container {
    padding-top: 20px; /* Уменьшаем padding для компактности */
  }

  .mainContent {
    flex-direction: column; /* Делаем контейнер вертикальным */
    gap: 20px; /* Уменьшаем расстояние между элементами */
    align-items: center; /* Выравниваем элементы по центру */
  }

  .svgContainer {
    width: 90%; /* Делаем контейнер SVG занимать всю ширину */
    min-width: auto; /* Убираем ограничение минимальной ширины */
    height: 300px; /* Высота может быть динамической, чтобы адаптироваться к контенту */
    padding: 20px; /* Уменьшаем padding для компактности */
   
  }

  .controls {
    width: 100%; /* Контролы занимают всю ширину */
    min-width: auto; /* Убираем ограничение минимальной ширины */
    gap: 15px; /* Оставляем небольшой промежуток */
  }


  .keywords {
    font-size: 10px; /* Уменьшаем размер шрифта для мобильных устройств */
  }

  .colorSets {
    margin-top: 5px; /* Уменьшаем отступ сверху */
    gap: 5px; /* Уменьшаем расстояние между цветовыми блоками */
  }
}
