.open-popup-btn {
    padding: 10px 20px;
    font-size: 16px;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Черный полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background-color: white;
    padding: 5%; /* Процентные отступы */
    border-radius: 10px;
    width: 90%;
    max-width: 500px; /* Оптимизация ширины для мобильных устройств */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center; /* Выравнивание содержимого по центру */
}

.popup-content h2 {
    font-size: 32px; /* Увеличение размера заголовка */
}

.popup-content a {
    color: black; /* Цвет ссылок черный */
    text-decoration: underline; /* Подчеркивание ссылок */
}

.popup-image {
    width: 100%;
    height: auto;
    max-height: 150px; /* Адаптивная максимальная высота */
    border-radius: 10px;
    margin-top: 20px; /* Отступ сверху */
    margin-bottom: 20px; /* Отступ снизу */
}

.close-popup-btn {
    display: inline-block; /* Изменение на inline-block для корректного выравнивания */
    margin-top: 20px;
    padding: 12px 24px; /* Увеличенные отступы для кнопки */
    font-size: 16px;
    background-color: black; /* Черный фон для кнопки */
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center; /* Текст кнопки по центру */
}

.close-popup-btn:hover {
    background-color: #333; /* Темнее черный при наведении */
}
