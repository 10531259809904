.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-item {
  text-decoration: none;
  color: var(--page-primary);
  font-size: 14px; /* Одинаковый размер шрифта для всех элементов */
  font-weight: 600; /* Возвращаем обратно более жирный шрифт для элементов навигационной панели */
  margin-right: 20px;
}

.navbar-item:hover {
  color: var(--page-secondary);
}

.energy {
  margin: 0 10px;
}

.user-menu {
  position: relative;
  cursor: pointer;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Делает изображение круглым */
}

.dropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
  z-index: 1;
  right: 0;
  top: 40px;
  width: 210px;
  border-radius: 8px;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-weight: 400; /* Тонкий шрифт для элементов выпадающего меню */
}

.menu-item:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.button {
  background-color: #3367D6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}
