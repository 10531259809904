.google-sign-in-button, .apple-sign-in-button {
    display: grid;
    grid-template-columns: 30px 1fr;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 360px;
    border-radius: 50px;
    border: 1px solid #989898;
    margin: 10px auto;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
  }
  
  .google-icon, .apple-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
  }
  .logo_big {
    width: 100px;
    height: 100px;
    margin: 0;
    padding: 0;
}
.login-h1 {
    font-size: 44px;
    font-weight: 700;
    margin-top: 100px;
    padding: 0;
}
  .google-sign-in-button:hover, .apple-sign-in-button:hover {
    background-color: #f1f1f1;
}
.centered_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
}
  /* Add more CSS based on your design requirements */
  