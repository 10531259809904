.cookie-banner {
  position: fixed; /* Изменено с absolute на fixed */
  right: 20px;
  bottom: 20px;
  width: 300px;
  background-color: #fff;
  color: #333;
  padding: 20px;
  text-align: center;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.close-button {
  align-self: flex-end;
  border: none;
  background: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
}

.title-cookie{
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customize-button {
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  flex: 1;
  margin-right: 5px;
}

.reject-button {
  background-color: grey;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  flex: 1;
  margin-right: 5px;
}

.accept-button {
  background-color: blue;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  flex: 1;
}
