/* src/styles/RatingToolPage.css */

body {
    padding-top:0px !important;
}
.rating-tool-page {
    background-color: #000; /* Черный фон */
    min-height: 100vh; /* Обеспечивает, что фон занимает всю высоту окна */
    padding: 20px; /* Отступы по желанию */
    box-sizing: border-box;
    position: relative;
  }
  
  /* Дополнительные стили для индикатора нажатия клавиши 'D' */
  .d-key-indicator {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    font-weight: bold;
  }
  